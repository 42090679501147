import { FunctionComponent, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useUser } from '../../services/hooks'
import { authService } from '../../services/service'
import { routes } from '../../../../routes'
import { config } from '../../../../config'
import { IUser, Role, Scope } from '../../services/types'

export function toUserRole(scope: Scope, role: Role) {
    return `${Scope[scope]}.${Role[role]}`
}

export function authorized(user?: IUser | null, roles?: string[]) {
    if (user) {
        if (!roles || roles.length <= 0) {
            return true
        }

        if (Array.isArray(user.role)) {
            return user.role.some(r => roles.includes(r))
        }

        return roles.includes(user.role)
    }

    return false
}

export interface AuthParams {
    content: (user: IUser, onLogout: () => void) => JSX.Element
}

export const Auth: FunctionComponent<AuthParams> = ({ content }) => {
    const user = useUser()

    const history = useHistory()

    useEffect(() => {
        if (user === null) {
            history.push(routes.login)
        }
    }, [user, history])

    const handleOnLogout = async () => {
        try {
            await authService.getLogout()
        } catch {}

        history.push(config.defaultPath)
        history.push(routes.login)
    }

    return user ? content(user, handleOnLogout) : null
}
